@import './../../../styles/variables.scss';

.enableExportJobData {
  .ui {
    &.segment {
      &.container-jobHistoryDetails {
        min-width: 300px;
        padding: 16px;

        @media (max-width: $mobileBreakPoint) {
          padding: 0px;
          width: 100%;
        }
      }
    }
  }

  &.ui {
    &.horizontal {
      &.segments {
        @media (max-width: $mobileBreakPoint) {
          margin: 0px;
          flex-direction: column;
        }

        &>.segment {
          @media (max-width: $mobileBreakPoint) {
            border-left: 0px;
          }
        }
      }
    }
  }

  .ui.segment.container-jobHistoryList {
    padding: 12px 12px 0px;
    max-width: 580px;
    width: 100%;

    @media (max-width: $mobileBreakPoint) {
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: $mobileBreakPoint) {
      padding: 0px;
    }

    .jobHistoryExportJobItemContent {
      padding: 12px;
      width: 100%;
      position: relative;
      margin: 0px;


      &+* {
        margin-top: 12px;
      }

      .container-jobHistoryTime {
        margin-top: -4px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .container-jobHistoryStatus {
          white-space: nowrap;
        }
      }

      .jobHistoryTime {
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        display: block;
        margin: 0px;
        padding: 4px 10px 4px 0px;

        @media (max-width: $mobileBreakPoint) {
          font-size: 14px;
          line-height: 1.25;
          padding: 0px 10px 0px 0px;
        }

        .container-JobHistorySeporator {
          @media (max-width: $mobileBreakPoint) {
            display: none;
          }
        }

        span {
          @media (max-width: $mobileBreakPoint) {
            display: block;
          }
        }
      }

      .container-jobHistoryType {
        margin-top: -6px;

        @media (max-width: $mobileBreakPoint) {
          margin-top: 0px;
        }
      }

      .jobHistoryType {
        font-size: 14px;
        font-weight: $regular;
      }

      .jobHistoryLink {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      .container-jobHistoryMessage {
        margin-top: 6px;
        max-width: 450px;

        .container-jobHistoryControl {
          margin-left: 6px;

          button {
            color: $blue;
            line-height: inherit;
            height: auto;

            @media (max-width: $mobileBreakPoint) {
              font-size: 12px;
              line-height: 1.25;
            }
          }
        }
      }

      .jobHistoryMessage {
        font-size: 16px;
        color: $dark-grey;

        @media (max-width: $mobileBreakPoint) {
          font-size: 12px;
          line-height: 1.25;
        }
      }
    }
  }
}

.ui {
  &.grid {
    &>.row {
      &.container-triggerExportJobNotifications {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }
}