/*******************************
         Site Overrides
*******************************/

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label{
  line-height: 32px;
}
.ui.checkbox.radio label{
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}
.ui.checkbox.radio label:before,
.ui.checkbox.radio input:checked:focus ~ label:before,
.ui.checkbox.radio input:checked ~ label:before{
  border-color: #00629B;
}
.ui.radio.checkbox label:after{
  background-color: #00629B;
}