@import './../../../styles/variables.scss';

.ui.form,
* {

  .container-datePickerField {
    position: relative;
    z-index: 1;

    .icon {
      position: absolute;
      top: calc(50% - 8px);
      right: 6px;
      color: $aluminium;
      opacity: 0.8;
      z-index: -1;
    }

    input[type="text"] {
      padding-right: 36px;
      background-color: transparent;
    }

    .react-datepicker__close-icon {
      padding-right: 10px;

      &:after {
        width: 20px;
        height: 20px;
        font-size: 14px;
      }
    }

    .container-datePickerInput {
      height: 32px;
      padding: 7px 16px;
      font-size: 16px;

      &.error-datePicker-field {
        border-color: $monza;
      }
    }
  }
}