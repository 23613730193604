.administration-tab {
  flex: 1;
  text-align: left;

  &-container {
    display: flex;
    align-items: flex-start;
  }

  .buttons-container {
    margin-bottom: 24px;
    text-align: end;
  }
}