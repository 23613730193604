@import '../../styles/variables';

.auditMessage {
  font-size: 16px;
  width: 364px;
  margin-left: 24px;
  margin-bottom: 1em;
}

.auditInfo {
  position: relative;
  width: 364px;
  margin-left: 24px;
  padding: 24px;
  background: $white;
  word-break: break-word;
  box-shadow: 0 2px 1px $grey, 0px 0px 1px $grey-middle;
  border-radius: 8px;

  .values {
    font-size: 0;
    margin: 0px -4px 0px;

    .ui {
      &.label {
        margin: 4px;
      }
    }

    .value-container {
      display: inline-block;
      vertical-align: top;
      height: 24px;
      margin: 4px;
      padding: 0 8px;
      border-radius: 4px;
      line-height: 24px;
      background: $rolling-stone;
      color: $white;
      font-size: 14px;
      box-sizing: border-box;
    }
  }

  @media (max-width: 1200px) {
    width: 300px;
  }

  @media (max-width: $minMobileBreakPoint) {
    padding: 24px 1rem;
  }

  .blueEmail {
    color: $bahama-blue;
  }

  .grey {
    color: $rolling-stone;
  }
}