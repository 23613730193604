@import './../../../styles/variables.scss';

.rowTableExportJobs {
  @media (max-width: $tabletBreakPoint) {
    position: relative;
  }

  .container-controlsDropdownExportJobs {
    @media (max-width: $tabletBreakPoint) {
      position: absolute;
      right: 10px;
      top: 18px;
    }
  }
}