.overlay-message {
    top: 0px;
    left: 0px;
    z-index: 1001;
}

.allow-overflow {
    overflow: visible !important;
}

.content-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.loader-container {
    flex: 1;
}