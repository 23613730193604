.expandable-panel {
    background-color: white;
    border-right: 1px solid rgb(179, 176, 173);
    padding: 12px;
}

.title-label {
    font: 'Segoe UI';
    font-size: 16px;
    font-weight: 600;
    color: rgb(71, 71, 71);
}

.rotated-title-label {
    font: 'Segoe UI';
    font-size: 16px;
    font-weight: 600;
    color: rgb(71, 71, 71);
    transform: rotate(90deg);
    white-space: nowrap;
    padding-left: 16px;
}

.state-toggler:hover {
    cursor: pointer;
}

.sidebar-panel {
    margin-top: 18px;
}

.sidebar-content {
    flex: 1;
}