@import '../../../styles/variables';

.ticketsForm {
  position: relative;
  z-index: 2;
  
  &.ui.form.form-container {
    padding: 16px;

    @media (max-width: $mobileBreakPoint) {
      position: relative;
      padding-bottom: 65px;
    }

    @media (max-width: $minMobileBreakPoint) {
      padding: 24px 1rem 65px;
    }

    .fields,
    .fields:not(.unstackable) {
      @media (max-width: $mobileBreakPoint) {
        flex-wrap: wrap;
        margin-left: -4px;
        margin-right: -4px;
      }

      &>.field {
        @media (max-width: $mobileBreakPoint) {
          padding: 4px;
          margin: 0px;
          width: 100% !important;
          max-width: 240px;
        }

        @media (max-width: $minMobileBreakPoint) {
          max-width: 345px;
        }
      }
    }
  }

  .ui.small.button {
    margin-right: 0;

    &>.icon:not(.button):not(.dropdown) {
      margin: 0;
    }
  }

  .ui.form .ui.small.input input {
    height: 32px;
  }

  .clear {
    width: 32px;
    height: 32px;
    padding-left: 8px;
  }

  .tips-text {
    font-size: 14px;
    line-height: 16px;
    color: $osloGray;

    @media (max-width: $mobileBreakPoint) {
      display: none;
    }
  }

  &.ui.form {
    .btns-container {
      display: flex;
      align-items: center;
      flex: none;
      margin: 25px 0px 16px 8px;

      @media (max-width: $mobileBreakPoint) {
        margin: 16px 8px 0px;
      }

      .container-mainControls {
        display: flex;
        align-items: center;
        padding-right: 16px;
        margin-right: 22px;

        @media (max-width: $mobileBreakPoint) {
          position: absolute;
          bottom: 16px;
          right: 16px;
          margin: 0px;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          padding: 0px;
          border: 0px;
        }

        .clear {
          cursor: pointer;
          margin-left: 7px;
          color: $black;
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }

          @media (max-width: $mobileBreakPoint) {
            margin-right: 16px;
          }

          .icon {
            &:before {
              line-height: 30px;
            }
          }
        }
      }
    }
  }

  &.form-error {
    .tips-text {
      color: $monza;
    }

    &.ui.form input {
      border-color: $monza;
    }
  }
}