@import './../../../styles/variables';

.administration-tab {
  .administration-tab-container {
    display: flex;
    align-items: flex-start;
    &.administration-reports-tab {
      .ui {
        &.table {
          .administrationTable-col-0 {
            width: calc(1.5rem + 12px * 2);

            .reportIcon {
              &.icon {
                display: flex;
                text-align: center;
                justify-content: center;
                font-size: 1.5rem;
                line-height: 1;
                color: $bahama-blue;
                margin: 0px;
                width: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}