@import './../../../../styles/variables';

.DataAuthorizationRolesForm {
  position: relative;
  width: 100%;
  text-align: left;
  overflow: hidden;
  flex: 1;

  .form-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .ui.button{
      margin-right: 0px;
    }
  }

  &.ui.form .field.small-margin {
    margin-bottom: 8px;
  }

  .button-container.inline.fields {
    justify-content: end;
    margin-top: 24px;
    padding: 24px;
    text-align: right;
    background: $aquaHaze;
    border-radius: 8px;
  }

  .link-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 32px;
    color: $bahama-blue;
    cursor: pointer;
  }

  .ui.selection.dropdown {
    min-width: 0;
  }

  .accordion {
    position: relative;
    margin-bottom: 24px;

    .headline {
      display: block;
      width: 100%;
      font-size: 16px;
      cursor: pointer;
      font-weight: $semibold;
      color: $shark;
    }

    .text {
      margin-bottom: 16px;
      overflow: hidden;
      height: 0;
      opacity: 0;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      color: $rolling-stone;
    }

    input[type=checkbox] {
      display: none;
    }

    input[type=checkbox]:checked ~ .text {
      height: auto;
      margin-top: 10px;
      opacity: 1;
    }
  }

  .container-dataAuthorizationRoleForm{
    &.ui{
      &.grid{
        & > .row{
          & > .column{
            &.container-dataAuthorizationRoleFormField{
              width: auto;
              min-width: 25%; 
              max-width: calc(100% - 150px);
            }
            &.container-dataAuthorizationRoleFormControl{
              width: auto;
              max-width: 150px;
              & > *{
                padding-top: 25px;
              }
            }
          }
        }
      }
    }
  }
}
