@import './../../../../styles/variables';

.reportForm {
  position: relative;
  width: 300px;
  margin-left: 24px;
  padding: 24px;
  background: $white;
  text-align: left;
  box-shadow: 0 2px 1px $grey, 0px 0px 1px $grey-middle;
  border-radius: 8px;

  .paginatedReportLabel {
    position: absolute;
    right: 0px;
  }
}