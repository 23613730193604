@import './styles/variables.scss';

body {
  margin: 0;
  font-family: Proxima Nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $alabaster;
}

@font-face {
  src: url('./styles/fonts/proximanova/ProximaNovaW08-Regular.woff') format('woff');
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-display: swap;
}

@font-face {
  src: url('./styles/fonts/proximanova/ProximaNovaW08-Semibold.woff') format('woff');
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-display: swap;
}

@font-face {
  src: url('./styles/fonts/proximanova/ProximaNovaW08-Bold.woff') format('woff');
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.formError {
  padding-top: 8px;
  padding-bottom: 24px;
  word-break: break-word;
  color: $monza;
}

.formSuccess {
  padding-top: 8px;
  padding-bottom: 24px;
  word-break: break-word;
  color: $persianGreen;
}

.container {
  padding: 24px;
  text-align: left;
}

.ui.table td.right,
.ui.table th.right {
  text-align: right;

  @media (min-height: 720px) {
    text-align: inherit;
  }
}

.header-icon-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;

  .header {
    margin: 0;
    font-size: 24px;
    line-height: 30px;
  }

  &+.text {
    margin-top: 8px;
  }
}

@media (max-width: $minMobileBreakPoint) {
  .container {
    padding: 12px;
  }
}