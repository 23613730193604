@import '../../styles/variables';

.tickets-container {
  .info-text {
    padding: 16px;
    font-weight: $semibold;
  }

  .info-container {
    position: relative;
    display: flex;
    margin-top: 24px;

    .ui.loader {
      top: 50px
    }
  }
}

