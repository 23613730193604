@import './../../../styles/variables.scss';

.ui {
  &.form {

    .fields,
    & {
      .field {
        .ui {
          &.selection {
            &.iconsSelect {
              .divider.text {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                line-height: 1;

                .icon {
                  font-size: 2rem;
                  line-height: 1;
                  color: $bahama-blue;
                  width: auto;
                  margin-top: -2px;
                  margin-bottom: -2px;
                }
              }

              .menu {
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;

                &.transition {
                  &.visible {
                    display: flex !important;
                  }
                }

                .item {
                  width: 25%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .icon {
                    font-size: 2rem;
                    line-height: 1;
                    color: $bahama-blue;
                    width: auto;
                    margin: 0px;
                  }

                  .text {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}