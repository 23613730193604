@import '../../styles/variables.scss';

.administration {
  .ui.menu {
    &.secondary.pointing {
      margin-bottom: 0px;

      .item {
        font-weight: $semibold;
      }
    }
  }

  .administrationTabContent {
    margin-top: 24px;
  }
}