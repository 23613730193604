@import './../../../../styles/variables';

.featureAccessGroupsTable-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .staticNotificationMessage{
    align-self: flex-start;
  }

  .ui {
    &.table {
      &.lastColumnControl {

        thead,
        tbody {
          tr {

            th,
            td {
              &:last-child {
                width: 16px;
                padding: 0px;

                &>* {
                  padding: 12px;
                }
              }
            }
          }
        }
      }

      thead {
        tr {
          th {
            .icon {
              height: 16px;
              width: 16px;
              margin: 0px;
              cursor: pointer;
              transform: scale(1) rotate(0deg);
              transition: transform $animationDuration;
              margin-left: 2px;

              &.up,
              &.down {
                &:hover {
                  cursor: pointer;
                  transform: scale(2);
                }
              }

              &.up {
                transform: scale(1) rotate(0deg);

                &.active {
                  transform: scale(1.25) rotate(0deg);

                  &:hover {
                    transition: transform $animationDuration 0.5s;
                    transform: scale(2) rotate(180deg);
                  }
                }
              }

              &.down {
                transform: scale(1) rotate(180deg);

                &.active {
                  transform: scale(1.25) rotate(180deg);

                  &:hover {
                    transition: transform $animationDuration 0.5s;
                    transform: scale(2) rotate(0deg);
                  }
                }
              }

            }
          }
        }
      }
    }
  }
}