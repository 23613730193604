@import './../../../styles/variables';

.dataAuthorizationRoles {
  text-align: left;

  .ui.form .formError {
    padding: 0 20px 20px;
    background: $white;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
