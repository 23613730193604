@import './../../../../styles/variables';

.featureAccessGroupForm {
  position: relative;
  width: 364px;
  margin-left: 24px;
  padding: 24px;
  background: $white;
  text-align: left;
  box-shadow: 0 2px 1px $grey, 0px 0px 1px $grey-middle;
  border-radius: 8px;

  .form-subheader {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 30px;
  }
}
