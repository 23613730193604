@import './../../../../styles/variables';

.petUserForm {
  position: relative;
  width: 364px;
  margin-left: 24px;
  padding: 24px;
  background: $white;
  text-align: left;
  box-shadow: 0 2px 1px $grey, 0px 0px 1px $grey-middle;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 300px;
  }
}