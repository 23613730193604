/*******************************
         Site Overrides
*******************************/
.ui.table:not(.static) tr:hover {
  cursor: pointer;
}
.ui.table td {
  height: 40px;
}
.ui.table tbody tr:hover {
  background: #EFF4F6;
}

.ui.celled.table tr th,
.ui.celled.table tr td{
  max-width: 300px;
}

.ui.celled.table tr td{
  white-space: break-spaces;
  word-break: break-word;
}

.ui.celled.table tr th i.icon.leftIcon{
  margin: 0px 8px 0px;
}

@media (max-width: 1600px){
  .ui.celled.table tr th,
  .ui.celled.table tr td:not(:first-child){
    &:not(:first-child) {
      padding-left: 6px;
    }
    &:not(:last-child) {
      padding-right: 6px;
    }
  }
}