@import '../../../styles/variables';

.round-initial {
  flex: none;
  height: 34px;
  width: 34px;
  margin-right: 12px;
  margin-top: 4px;
  border-radius: 50%;
  line-height: 34px;
  background: $cornflowerBlue;
  color: $white;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: $bold;
  display: inline-block;
}