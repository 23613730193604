@import '../../../styles/variables';

.searchRestrictionsHeader {
  padding: 16px;
  margin: 8px 0;
  border: 1px solid $iron;
  border-radius: 8px;
  background: $alabaster;

  .ui.input input {
    width: 100%;
  }

  .search-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    color: $bahama-blue;
    box-sizing: border-box;

    .pointer-text {
      min-width: 140px;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}