@import '../../styles/variables.scss';

.report-container {
  position: relative;

  &.fullscreen {
    top: 0;
    background: $white;

    &,
    .report-style-class  {
      position: fixed;
      left: 0;
      z-index: 1000;
      width: 100%;
      height: 100vh;
    }

    .report-style-class  {
      top: 30px;
      height: calc(100vh - 30px);
    }

    .btn-container {
      top: 0;
    }

    &.dashboard-report {
      .report-style-class {
        min-height: 0.84vw;
      }
    }
  }

  .btn-container {
    position: absolute;
    top: -26px;
    right: 0;

    .btn-label {
      z-index: 2;
      position: absolute;
      top: 0;
      right: 100%;
      width: 140px;
    }
  }

  .expand.icon,
  .compress.icon {
    cursor: pointer;

    &:hover {
      color: $cornflowerBlue;
    }
  }

  .sticky-btn {
    height: 30px;
    border: none;
    line-height: 26px;
    background: $white;
    color: $bahama-blue;
    cursor: pointer;

    &:disabled {
      color: rgba($bahama-blue, 0.45);
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 100%;
      display: block;
      width: 30px;
      height: 30px;
      background: radial-gradient(60px at top left, transparent 49%, $white 51%) no-repeat right bottom;
    }
  }

  .report-style-class {
    width: 100%;
    height: calc(100vh - 160px);
    min-height: 520px;
    background: $alabaster;
  }

  &.dashboard-report {
    .report-style-class {
      min-height: 0.84vw;
    }
  }

  iframe {
    border: none;
  }
}
