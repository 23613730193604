/*******************************
         Site Overrides
*******************************/

h1.ui.header{
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #19191A;
  margin: 0px;
}

h1.ui.header + *{
  margin-top: 16px !important;
}

h2.ui.header{
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #19191A;
  margin: 0px;
}

h3.ui.header{
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #76787A;
  margin: 0px;
}

h3.ui.header + *{
  margin-top: 16px !important;
}

h4.ui.header{
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: #76787A;
  margin: 0px;
}

h4.ui.header + *{
  margin-top: 0px !important;
}