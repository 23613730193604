/*******************************
         Site Overrides
*******************************/

.ui.form .field > label {
  display: table;
}

.ui.form > .field + .field,
.ui.form > .fields + .field,
.ui.form > .field + .fields,
.ui.form > .fields + .fields{
  margin-top: 24px;
}

.ui.form > .field > .ui.label{
  margin: 6px 0px 0px;
}

.ui.form .fields .field .ui.input input, .ui.form .field .ui.input input {
  width: 100%;
}

.ui.form .inline.fields .field > :first-child,
.ui.form .inline.field > :first-child {
  margin: 0em @inlineLabelDistance 8px 0em;
}

.ui.form > .fields > .field + .field {
 margin-left: 8px;
 margin-top: 0px;
}

.ui.large.input input {
 padding: 11px 16px;
 line-height: 24px;
}

.ui.form .field > .selection.dropdown.loading > .dropdown.icon {
 position: absolute;
 left: 0;
 float: none;
 top: 5px;
 display: inline-block;
}

.ui.form .success.message,
.ui.form .warning.message,
.ui.form .error.message{
  display: block;
}

.ui.form textarea {
  font-family: @pageFont;
}