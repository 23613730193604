@import '../../../styles/variables';

.customLabel {
  flex: none;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  margin-left: auto;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  color: $white;
  background: $rolling-stone;

  &+.customLabel {
    margin-left: 8px;
  }

  &.grey {
    background: $athensGray;
    color: $shark;
  }

  &.light-orange {
    background: $flamingo;
  }

  &.green {
    background: $persianGreen;
  }

  &.pink {
    background: $mandy;
  }

  &.orange {
    background: $flamingo;
  }

  &.blue {
    background: $lochmara;
  }

  &.sky {
    background: $cerulean;
  }

  &.purple {
    background: $affair;
  }

  &.red {
    background: $crimson;
  }
}