/*******************************
         Site Overrides
*******************************/

.ui.pagination.menu {
  border: none;
  margin: 16px;
  background: transparent;
}

.ui.pagination.menu .item {
  justify-content: center;
  margin: 0 8px;
  padding: 8px;
  border: 1px solid #E1E3E6;
  border-radius: 3px;
  color: #19191A;
  background: #ffffff;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.ui.pagination.menu .item[type="ellipsisItem"] {
  border: none;
  color: #76787A;
  background: transparent;
}

.ui.pagination.menu .item:before {
  content: none;
}

.ui.pagination.menu .active.item {
  padding-top: 8px;
  border: 1px solid #00629B;
  color: #00629B;
  background: #ffffff;
}

