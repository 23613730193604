@import './../../../styles/variables.scss';

.formFieldLabel {
  &.required {
    position: relative;

    &:before {
      content: '*';
      position: absolute;
      top: -4px;
      right: -4px;
      color: $monza;
    }
  }
}