@import '../../../styles/variables';


.tripsTicketsSearchWrapper {
  flex-flow: column;

  .tripsTicketsTable-container{
    display: flex;
        
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tripsTicketsSearchTable-container {

  .ui.table {
    table-layout: fixed;
  }

  .ui.celled.table tr td {
    word-break: break-word;
  }

  .ui.table thead tr:first-child>th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .table-header {
    font-weight: $semibold;
    color: $shark;
  }

  &.hidden {
    display: none;
  }
}