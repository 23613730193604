.App {
  position: relative;
  min-height: 100vh;
  text-align: center;
  display: flex;
}

.main-content {
  position: relative;
  flex: 1;
  padding-bottom: 64px;
  max-width: calc(100% - 64px);
}

@media (max-width: 1280px) {
  .main-content {
    padding-left: 56px;
    padding-bottom: 88px;
  }
}