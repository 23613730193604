@import '../../styles/variables.scss';

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px 24px;
  text-align: center;
  color: $aluminium;

  @media (max-width: $mobileBreakPoint) {
    z-index: 3;
    width: calc(100% - 56px);
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
    color: $aluminium;
    padding: 12px;
  }

  .link {
    color: $aluminium;
  }
}
