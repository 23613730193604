@import '../../styles/variables.scss';

.authentication {
  display: flex;
  min-height: 100vh;
  font-size: 16px;
  line-height: 24px;

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 85px;
    background: $white;

    @media (max-width: $tabletLandscapeBreakPoint) {
      padding: 16px 88px;
      width: 100%;
      align-items: center;
    }

    @media (max-width: $minMobileBreakPoint) {
      padding: 24px;
    }

    .container-emailSignIn {
      padding-top: 26px;
    }
  
    .container-defaultSignIn {
      padding-top: 34px;
  
      .separator {
        margin-top: 16px;
      }
    }

    .container-logoWrapper {
      width: 330px;
    }

    .form {
      width: 330px;

      h2 {
        font-size: 27px;
        line-height: 30px;
        color: $shark;
      }

      .field {
        &+.button {
          margin-top: 24px;
        }
      }
    }

    .center-content {
      text-align: center;
      margin-top: 24px;

      .federatedSignIn {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin-bottom: 10px !important;
        }
      }

      .emailSignIn {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          margin-bottom: 10px !important;
        }
      }
    }

    .separator {
      position: relative;
      margin: 24px 0px;
      font-weight: $bold;
      color: $rolling-stone;
      text-align: center;

      @media (max-height: 720px) {
        margin: 16px 0;
      }

      .ui {
        &.button {
          margin: 0px;
        }
      }

      &>* {
        cursor: pointer;
        opacity: 1;
        transition: $animationDuration;

        &:hover {
          opacity: 0.5;
        }
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 35%;
        height: 1px;
        background: $iron;
      }

      &:before {
        right: 0;
      }

      &:after {
        left: 0;
      }
    }

    .link-text {
      display: inline-block;
      font-weight: $bold;
      color: $bahama-blue;
      margin: 0px;
    }

    .formError {
      padding-top: 0;
    }

    .form-container-footer {
      padding-top: 16px;
    }
  }

  .banner {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url("../../assets/banner.png");
    background-size: cover;
  }
}

.banner {
  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1600px) {
    background-position: 10%;
  }

  .info {
    padding: 80px 64px;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.48px;
    color: $white;
  }

  h1 {
    margin: 14px 0 0;
    font-weight: $bold;
    font-size: 42px;
    line-height: 50px;
  }
}

.form-container-footer {
  font-size: 14px;
  line-height: 16px;
  color: $aluminium;
  width: 330px;

  .link {
    color: $bahama-blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    +.link {
      margin-left: 4px;
    }
  }
}