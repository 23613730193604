@import '../../styles/variables.scss';

.administrationTable {
  flex: 1;
  text-align: center;
  width: 100%;

  &.hidden {
    flex: none;
    width: 0;
    overflow: hidden;
    display: none;
  }

  .blur-table {
    position: relative;

    &:after {
      content: "";
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $blur;
    }
  }

  .ui.celled.table.right-column tr td,
  .ui.table.right-column thead tr th {
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }

  .ui.table.right-column thead tr th {
    text-align: center;
  }

  .ui.celled.table.last-column-auto-width {
    overflow: hidden;
  }

  &:not(.readOnlyMode) {
    .ui.celled.table tr td:last-child,
    .ui.table thead tr th:last-child {
      width: 48px;
      padding: 0;

      .ui.dropdown,
      i.icon.pencil {
        padding: 0 12px;

        &:hover {
          color: $dark-grey;
        }
      }
    }
  }

  .check.circle.outline.icon {
    color: $bahama-blue;
  }

  .ui.celled.table.last-column-auto-width tr td:last-child,
  .ui.table.last-column-auto-width thead tr th:last-child {
    width: auto;
    padding: 12px;
  }

  @media (max-width: 1440px) {
    .ui.celled.table.compressed-table tr th {
      word-break: break-word;
    }

    .ui.celled.table.compressed-table tr td,
    .ui.celled.table.compressed-table tr th {
      overflow: hidden;
      text-overflow: clip;
      max-width: 120px;
      box-shadow: -3px 0 5px -5px $aluminium;
    }

    .ui.celled.table tr td:last-child,
    .ui.table thead tr th:last-child {
      overflow: inherit;
    }
  }

  @media (max-width: 1330px) {

    .ui.celled.table.compressed-table tr td,
    .ui.celled.table.compressed-table tr th {
      max-width: 100px;
    }
  }

  @media (max-width: 1260px) {

    .ui.celled.table.compressed-table tr td,
    .ui.celled.table.compressed-table tr th {
      max-width: 85px;
    }

    .ui.celled.table tr td,
    .ui.celled.table tr th {
      overflow: hidden;
      text-overflow: clip;
      max-width: 120px;
      box-shadow: -3px 0 5px -5px $aluminium;
    }
  }

  @media (max-width: 1120px) {
    .ui.table {
      width: 100%;
    }

    .ui.celled.table.compressed-table tr td,
    .ui.celled.table.compressed-table tr th {
      max-width: 70px;
    }
  }

  @media (max-width: 960px) {

    .ui.celled.table.compressed-table tr td,
    .ui.celled.table.compressed-table tr th {
      max-width: 60px;
    }

    .ui.celled.table tr td,
    .ui.celled.table tr th {
      max-width: 100px;
      padding: 12px 8px;
    }
  }

  @media (max-width: 860px) {

    .ui.celled.table tr td,
    .ui.celled.table tr th {
      max-width: 80px;
    }
  }

  @media (max-width: 767px) {

    .ui.celled.table tr td,
    .ui.celled.table tr th,
    .ui.celled.table.compressed-table tr td,
    .ui.celled.table.compressed-table tr th {
      max-width: none;
    }
  }
}