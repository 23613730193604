@import './../../../styles/variables.scss';

.labelWrapper{
  .labelWrapper-label {
    display: block;
    margin: 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: $regular;
    color: $rolling-stone;
  }

  .labelWrapper-children {
    margin: 4px 0px 0px;
    font-size: 16px;
    line-height: 24px;
    font-weight: $regular;
    color: $shark;
  }
}