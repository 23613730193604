@import '../../../styles/variables';

.auditInfo {
  .calculationAuditInfo {

    label,
    div {
      word-break: normal;
    }

    .container-fareCalculation {
      word-break: break-all;
    }
  }
}

.container-TripAuditInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticketsAuditForm.ui.form {
  position: relative;

  .ui.button {
    margin-right: 0;
  }

  .field+.field {
    margin-left: 0;
  }

  .ticketsAuditForm-header-container {
    display: flex;
    justify-content: space-between;

    &+* {
      margin-top: 16px;
    }
  }

  .ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
    margin: 0;

    &:before {
      color: $bahama-blue;
    }
  }

  .fields:last-child .field,
  .fields.equal>.field {
    margin-bottom: 0;
  }

  .fields.equal {
    margin-bottom: 24px;
  }


  &-header-container {
    display: flex;
    justify-content: space-between;
  }


  .cards-container {
    display: flex;
    align-items: flex-start;
  }

  .airSegment-info {
    .auditInfo {
      @media (max-width: $minTabletBreakPoint) {
        padding: 24px 1rem;
      }
    }
  }

  .container-ticketCartTraveler {
    @media (max-width: $minTabletBreakPoint) {
      .paddedContent {
        padding-left: calc(46px + 1rem);
      }
    }
  }

  .cards-wrapper {
    flex: 1;
    width: 100%;
  }

  .initial-wrapper {
    display: flex;
  }

  .ticket-card {
    width: 100%;
    margin-left: 0;
    margin-bottom: 16px;
    padding: 0 0 8px;

    .card-body {
      .ui.divider {
        margin: 0px;
        position: relative;
      }
    }

    .card-body-item {
      padding: 16px 24px;

      @media (max-width: $minTabletBreakPoint) {
        padding: 24px 1rem;
      }
    }

    .card-sub-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;


      @media (max-width: $minMobileBreakPoint) {
        flex-direction: column;
        align-items: flex-start;
      }

      .container-badgesWrapper {
        margin-top: 4px;
      }
    }

    .field {
      margin-bottom: 24px;
    }

    .hidden-content {
      display: none;
    }

    .tableLabel {
      margin-top: 0;
      margin-bottom: 0;
    }

    .round-bg {
      display: inline-block;
      height: 24px;
      margin-right: 8px;
      line-height: 24px;
      border-radius: 6px;
      padding: 0 4px;
      background: $banube;
      color: $white;
    }

    .card-header {
      padding: 0px 24px;

      @media (max-width: $minTabletBreakPoint) {
        padding: 0px 8px;
      }

      &:hover {
        background: $athensGray;
        cursor: pointer;
      }

      .container-card-header {
        display: flex;
        align-items: center;
        min-height: 50px;
        padding: 8px 0px;

        .icon {
          margin-right: 18px;
        }

        @media (max-width: $minMobileBreakPoint) {
          flex-direction: column;
          align-items: flex-start;
          padding-left: 50px;

          .icon {
            position: absolute;
            left: 16px;
            top: 24px;
          }

          .badge {
            margin: 0px;
          }
        }
      }

      .ui.divider {
        margin: 0px;
      }
    }

    .calculationAuditInfo {
      padding: 8px;
      border-radius: 4px;
      background: $athensGray;

      @media (max-width: $minTabletBreakPoint) {
        .divider {
          display: none;
        }

        .ui {
          &.grid {
            &>.row {
              &.arbitraryWidth {
                flex-wrap: wrap;
              }
            }
          }
        }
      }
    }

    .fields>.field.left-column {
      flex: 1;
      margin-bottom: 0;
    }

    .fields>.field.right-column {
      margin-bottom: 0;
      margin-top: 0;

    }

    .small-group {
      padding-left: 14px;
      margin-bottom: 0;
      margin-left: 14px;
      border-left: 1px solid $iron;

      &>* {
        white-space: nowrap;
      }

      &>*+* {
        margin: 0 0 0 16px;
      }
    }

    .airSegment-wrapper {
      .airSegment-departure-wrapper {
        display: flex;
        align-items: center;
      }

      .airSegment-arrival,
      .airSegment-departure {
        &>* {
          display: block;
          white-space: nowrap;

          &+* {
            margin-top: 4px;
          }
        }
      }

      .airSegment-departure {
        padding-right: 2rem;
      }

      .plane-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: calc(50% - 0.5px);
          left: 0px;
          width: 100%;
          height: 1px;
          background: $heather;

          @media (max-width: $minTabletBreakPoint) {
            left: -1rem;
            width: calc(100% + 2rem);
          }
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: -10px;
          left: calc(50% - 20px);
          width: 40px;
          height: 40px;
          background: $white;

          @media (max-width: $minTabletBreakPoint) {
            top: -12.5px;
            left: calc(50% - 15px);
            width: 30px;
            height: 30px;
          }
        }

        .plane {
          position: relative;
          z-index: 1;
          width: 20px;
        }
      }

      .city-info,
      .airSegment-date {
        display: block;
        min-height: 16px;
        font-size: 14px;
        line-height: 16px;
        color: $rolling-stone;
      }

      .sub-header {
        font-weight: $semibold;
        color: $rolling-stone;

        &+* {
          margin-top: 10px;
        }
      }

      .airSegment-time {
        display: block;
        min-height: 16px;
        font-size: 14px;
        line-height: 16px;
        color: $shark;
        font-weight: $semibold;
      }
    }
  }

  .sticky-block {
    position: sticky;
    top: 16px;
    width: 420px;

    .fields:last-child {
      margin-bottom: 0;
    }
  }


  @media (max-width: $maxMediumDeviceBrakePoint) {
    .sticky-block {
      width: 360px;
    }
  }

  @media (max-width: $tabletLandscapeBreakPoint) {
    .cards-container {
      flex-direction: column;
    }

    .sticky-block {
      width: auto;
      margin-left: 0;
    }
  }
}

.ticket-card {
  .ui {
    &.grid {
      .row {
        &.container-ticketItems {
          @media (min-width: $minLargeDeviceBrakePoint) {
            &>.column {
              width: calc(100% / 3) !important;
            }
          }
        }
      }
    }
  }
}