.ui {
  &.form {

    .fields,
    & {
      .field {
        &.container-inputWithInfoLabel {
          margin-bottom: 8px;
          display: inline-block;

          &>label {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}