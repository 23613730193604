@import '../../styles/variables';

.help-container {
  .contact-information {
    width: 378px;
    text-align: left;

    .text {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 24px;
      color: $abbey;
    }
  }

  @media (max-width: $minMobileBreakPoint) {
    .contact-information {
      width: 100%;
    }
  }
}

