@import '../../styles/variables';

.viewExportJobs {
  .container-viewExportJobsWrapper {
    margin-top: 0px;
  }

  .container-viewExportJobHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}