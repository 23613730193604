@import './../../../styles/variables.scss';

.close-icon {
  z-index: 1;
  position: absolute;
  right: 24px;
  top: 24px;

  i.icon.close {
    margin: 0;
    display: block;
    text-align: center;
    width: 32px;
    height: 32px;
    font-size: 20px;
    line-height: 30px;
    font-weight: $bold;
    border-radius: 6px;
    border: 1px solid $bahama-blue;
    color: $banube;

    &:before {
      line-height: inherit;
      font-size: inherit;
    }

    &:hover {
      cursor: pointer;
      background: $pattensBlue;
    }
  }
}