@import '../../styles/variables';

.drawer {
   position: relative;

  #ham-menu {
    display: none;
  }

  #ham-menu:checked ~ .drawer-container {
    width: 218px;
  }

  .ham-menu-container {
    z-index: 103;
    position: absolute;
    left: 12px;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: $tangaroa;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .ham-menu {
    display: inline-block;
    width: 18px;
    height: 12px;
    background-image: url("../../assets/menuOpen.png");
    background-size: contain;
    cursor: pointer;
    transition: transform 0.3s ease;
    transform: scaleX(-1);
  }


  #ham-menu:checked ~ .ham-menu-container .ham-menu {
    transform: scaleX(1);
  }

  .drawer-container {
    overflow: hidden;
    transition: 500ms;
    width: 56px;
    height: 100%;
    min-height: 100vh;
    padding-top: 104px;
    background: $tangaroa;
    text-align: left;

    @media (max-width: $mobileBreakPoint) {
      position: absolute;
      display: inline-block;
      z-index: 100;
    }

    &-tabs {
      height: 100vh;
    }
  }


  @media (max-width: $minMobileBreakPoint) {
    .ui.vertical.menu .item {
      padding: 20px;

      & > i.icon {
        width: 18px;
      }
    }
  }
}
