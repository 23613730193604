@import '../../styles/variables';

.editExportJobs {
  @media (max-width: $mobileBreakPoint) {
    padding-top: 30px;
  }

  .container-editExportJobsWrapper {
    margin-top: 0px;
  }

  .container-editExportJobHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $mobileBreakPoint) {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .container-editExportJobGoBack {
      .ui {
        &.button.basic.blue {
          margin-right: 0px;

          @media (max-width: $mobileBreakPoint) {
            border: 0px;
            box-shadow: none !important;
            padding: 0px;
          }
        }
      }

      .icon:not(.button):not(.dropdown) {
        &.arrow {
          @media (max-width: $mobileBreakPoint) {
            margin-left: 0px;
          }
        }
      }

      .editExportJobGoBack_backText {
        @media (max-width: $mobileBreakPoint) {
          display: none;
        }
      }
    }
  }
}