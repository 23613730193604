@import '../../styles/variables';

.ui.form {
  .form-header {
    margin-bottom: 24px;
  }

  .formError {
    padding-bottom: 0;
  }

  &.form-container,
  .form-container {
    padding: 24px;
    background: $white;
    box-shadow: 0 2px 1px $grey, 0px 0px 1px $grey-middle;
    border-radius: 8px;
    text-align: left;

    &+.form-container {
      margin-top: 24px;
    }
  }

  .button-container {
    &.fields {
      justify-content: flex-end;
      margin: 24px 0px 0px;
    }

    .ui.button {
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}