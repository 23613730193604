@import './../../../styles/variables.scss';

.logoWrapper {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 48px;

  .container-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.whiteLogo {

    .slogan,
    .projectName {
      color: $white;
    }

    .container-projectName {
      border-color: $white;
    }
  }

  .slogan {
    font-size: 9px;
    line-height: 1;
    font-weight: $bold;
    color: $osloGray;
    margin-top: -2px;
    letter-spacing: -0.2px;
    text-transform: lowercase;
  }

  .container-projectName {
    padding-left: 18px;
    margin-left: 18px;
    border-left: solid 1px $iron;
    display: block;
    position: relative;
    top: 3px;
  }

  .projectName {
    font-size: 21px;
    line-height: 26px;
    color: $abbey;
    font-weight: $bold;
    white-space: nowrap;
  }

  a {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 2;
    text-indent: 101%;
    white-space: nowrap;
  }

  .logo {
    width: 120px;
    height: auto;
  }
}