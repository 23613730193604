@import '../../styles/variables';

.system {
  .ui.success {
    display: inline-block;
  }

  .ui.form .form-header {
    margin-bottom: 4px;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: $rolling-stone;
    margin-bottom: 16px;
  }

  .ui.dropdown {
    min-height: 32px;
    padding: 2px 24px 0 4px;
    border: 1px solid $aluminium;
  }

  .button-container {
    text-align: right;
  }

  .ui.multiple.search.dropdown.active > input.search {
    display: inline-block;
    width: 100% !important;
  }

  .ui.form .field > .selection.dropdown {
    max-width: 100%;
  }

  .ui.form .inline.fields.button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }

  .ui.form .formError {
    padding-top: 0;
  }
}
