@import '../../../styles/variables';

.ui {
  &.dropdown {
    &.dropdownWrapper-dropdownWithInfo {
      &.error {
        border-color: $monza;
      }

      &.dropdown>.label {        
        background: $athensGray;
        color: $shark;
        border: none;
        box-shadow: none;
        font-size: 1rem;
        font-weight: 400;
      }

      .sizer {
        display: none;
      }
    }

    &.dropdownWrapper-orderedValue {
      padding-left: 36px;
      counter-reset: section;

      .ui {
        &.label {
          display: table;
          position: relative;
          width: auto;

          &:before {
            counter-increment: section;
            content: counter(section) ".";
            display: block;
            position: absolute;
            left: -20px;
            color: $cod-gray;
          }
        }
      }
    }
  }
}

.dropdownWrapper-dropdown-tip {
  margin-top: 4px;
  font-size: 14px;
  line-height: 16px;
  color: $rolling-stone;
  white-space: pre-wrap;
}