@import './../../../../styles/variables';

.petUserDetailsLoader {
  .paragraph {
    .line:first-child {
      min-height: 24px;
    }
  }

  .paragraph:nth-child(3) {
    .line:first-child {
      min-height: 50px;
    }
  }
}