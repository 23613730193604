@import '../../../styles/variables';

.documentsTable {
  &.ui {
    &.table {
      &.collapsing {
        width: 100%;

        head {
          tr {
            &:hover {
              background: transparent;
            }
          }
        }

        &.celled {
          tr {
            &.positive {
              td {
                .file-name {
                  color: $persianGreen;
                }
              }
            }

            &.negative {
              td {
                .file-name {
                  color: $stiletto;
                }
              }
            }

            td {
              height: auto;
              [class*='download'] {
                cursor: pointer;
                transition: color 0.3s;

                &:hover {
                  color: $bahama-blue-hover;
                }
              }

              .icon-document-type {
                width: 32px;
                min-width: 32px;
                max-width: 32px;
                height: 32px;

                &.pdf {
                  background-image: url("../../../assets/icon/pdf.svg");
                }

                &.docx {
                  background-image: url("../../../assets/icon/docx.svg");
                }
              }

              .file-name {
                margin: 0 8px;
              }

              .documentsDownloadErrorText {
                display: block;
              }

              &.text-center {
                display: flex;
                align-items: center;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}