@import './../../../../styles/variables.scss';

.sendMFACode-wrapper {
  width: 330px;

  .container-sendMFACode {
    margin-top: 32px;
  }
}

.container-mfaLoader {
  height: 218px;
  width: 330px;
}