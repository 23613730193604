@import './../../../styles/variables.scss';

* + .shortNotification{
  margin-top: 6px;
}
.shortNotification {
  max-width: 200px;

  &>* {
    display: block;
    color: $light-grey;
    font-size: 10px;
    line-height: 1.25;
  }

  &.fullwidth-notification{
    max-width: 100%;
  }

  &.normal-notification{
    &>* {
      font-style: $normal-style;
      font-size: 12px;
    }
  }

  &.warning-notification {
    &>* {
      color: $tangerine;
    }
  }
}