@import './../../../styles/variables.scss';

.ui {
  &.button {
    &.textButton {
      white-space: nowrap;
      border: 0px;
      background: none;
      padding: 0px;
      font-size: 16px;
      font-family: Proxima Nova, sans-serif;
      font-weight: normal;
      color: $black;

      &:hover,
      &:focus{
        background-color: transparent;
      }

      &.disabled {
        background-color: transparent;
        color: $grey;
      }
    }
  }
}