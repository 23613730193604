/*******************************
         Site Overrides
*******************************/


.ui.grid > .row{
  margin: -1rem 0rem;
}
.ui.grid > .row > .column {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.ui.grid > .row.arbitraryWidth{
  flex-wrap: nowrap;
}
.ui.grid > .row.arbitraryWidth > .column{
  width: auto;
}
.ui.grid > .row > .column.divider {
  padding-left: 0px;
  padding-right: 0px;
}