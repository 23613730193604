@import '../../../styles/variables';

.dateInfo {
  margin-bottom: 16px;
  color: $shark;

  &.ui.grid {
    margin-bottom: 16px;
  }

  .grey {
    margin-bottom: 8px;
    font-weight: $regular;
    color: $rolling-stone;
  }
}