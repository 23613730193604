/*******************************
         Site Overrides
*******************************/

.ui.small.button {
  height: 32px;
  padding: 0 12px;
  line-height: 32px;
}
.ui.labeled.icon.button{
  padding-left: 2.25em !important;
}
.ui.labeled.icon.button > .icon {
  background-color: rgba(0, 0, 0, 0);
}
.ui.button:disabled, .ui.button.disabled{
  background-color: rgba(0, 0, 0, 0.16);
}
.ui.button + .ui.button{
  margin-left: 8px;
}

