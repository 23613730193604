/*******************************
        User Overrides
*******************************/
.ui.dropdown {
  width: 100%;
}

.ui.dropdown .menu {
  width: 100%;
}

.ui.dropdown.search > .text {
  line-height: @fieldsLineHeight;
  display: block;
}

.ui.multiple.dropdown > .label{  
    height: 24px;
}

.ui.dropdown.selection:not(.loading)  > .icon {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 12px;
  color: #AAAEB3;
  opacity: 0.8;
  cursor: pointer;
  transition: transform 300ms;
}

.ui.dropdown.selection.loading  > .icon,
.ui.dropdown.selection.loading.dropdown  > .icon {
  position: absolute;
  padding: 0px !important;
  width: 18px;
  height: 18px !important;
  margin: 0px;
  top: calc(50% - 9px);
  right: 8px;
}

.ui.dropdown.selection.active > .icon {
  transform: rotate(180deg);
}

.ui.loading.selection.dropdown > i.icon{
  padding: 1.25em 1.25em !important;
}
