@import './../../../styles/variables.scss';

.container-editExportJob {
  padding: 24px;
  background: $white;
  box-shadow: 0 2px 1px $grey, 0px 0px 1px $grey-middle;
  border-radius: 8px;
  text-align: left;

  @media (max-width: $mobileBreakPoint) {
    padding: 0px;
  }

  .container-editExportJobControlWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .container-exportJobEnableControl {
      margin-right: 48px;
    }
  }

  .container-exportJobContainer {

    &.ui {
      &.container {
        @media (max-width: $mobileBreakPoint) {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    }

    .container-periodFieldWrapper,
    .container-reportTypeFieldWrapper {

      &.container-periodFieldWrapper .container-periodControlsList,
      &.container-reportTypeFieldWrapper .container-reportTypeControlsList {
        .ui {
          &.checkbox {
            &.radio {
              display: block;

              label {
                font-size: 16px;
                line-height: 24px;
                display: inline-block;
              }

              &+* {
                margin-top: 4px;
              }
            }
          }
        }
      }
    }

    .container-cronFieldWrapper {
      &.ui.grid {
        &>.row {
          &>.column {
            &.container-shortNotification {
              @media (max-width: $maxTabletBreakPoint) {
                margin-top: calc(-1rem - 10px);
              }
            }
          }
        }
      }
    }

    .container-exportJobEnableControl {
      display: flex;
      height: 32px;
      align-items: center;
    }

    .container-deliveryMethodContentWrapper {
      margin-top: 0px;

      @media (max-width: $mobileBreakPoint) {
        padding: 1em;
      }

      .container-deliveryMethodSwitcherWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 12px;

        .ui {
          &.checkbox {
            input {
              &.hidden {
                &+label {
                  @media (max-width: $mobileBreakPoint) {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        &>* {
          &+* {
            margin-left: 24px;

            @media (max-width: $mobileBreakPoint) {
              margin-left: 12px;
            }
          }
        }
      }
    }
  }

  .createExportJobCustomPeriodField {
    position: relative;
    z-index: 1;
  }
}

.container-exportJobContainer {
  .ui.grid {
    &>.row {
      &>.column {
        &.container-shortNotification {
          padding-top: calc(22px + 1rem);

          @media (max-width: $maxTabletBreakPoint) {
            padding-top: 0px;
            margin-top: -1rem;
          }
        }
      }
    }
  }
}