@import '../../styles/variables.scss';

.tableLabel {
  display: flex;
  flex-direction: row;
  height: 24px;
  padding: 4px 12px;

  white-space: nowrap;

  &.ui.label {
    border-radius: 4px;
  }

  .hidden {
    display: none;
  }

  .round {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    background: $aluminium;

    &.active,
    &.blue {
      background: $bahama-blue;
    }

    &.orange {
      background: #F38316;
    }

    &.grey {
      background: $rolling-stone;
    }

    &.green {
      background: $persianGreen;
    }

    &.red {
      background: $crimson;
    }

    &.brown {
      background: $cafeRoyale;
    }
  }
}



