@import '../../styles/variables.scss';

.header-container {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 24px;

  .container-headerLogo {
    margin-top: -10px;

    @media (max-width: $tabletLandscapeBreakPoint) {
      transform: scale(0.80);
      margin: -10px -30px 0px;
    }
  }

  @media (max-width: $minMobileBreakPoint) {
    padding: 16px 12px;
    position: relative;
    z-index: 4;
  }

  .right {
    display: flex;
    margin-left: auto;
    flex-direction: row;
    align-items: center;
  }

  .username {
    font-size: 15px;
    line-height: 17px;
    color: $cod-gray;
    margin: 0 20px 0 0;

  }

  .ui.dropdown.user-logo {
    height: 36px;
    width: 36px;
    padding: 0;
    border-radius: 50%;
    background: $cornflowerBlue;
    color: $white;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;

    .text {
      margin: 0px;
    }

    @media (max-width: $mobileBreakPoint) {
      margin: 0px;
    }

    &:hover {
      border-radius: 50% !important;
      background: $bahama-blue;
    }

    .item a {
      color: $cod-gray;
    }
  }
}