@import '../../styles/variables.scss';

.homePage {

  .text {
    margin-bottom: 24px;

    p {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      color: $rolling-stone;

      @media (max-width: $mobileBreakPoint) {
        margin-bottom: 48px;
      }
    }
  }
}