@import '../../styles/variables.scss';

.reports {
  margin: 24px 24px 0;
  text-align: left;

  .ui {
    &.secondary {
      &.menu {
        flex-wrap: wrap;
        margin: -6px 120px 0px -3px;

        .ui.label {
          border-radius: 15px;
          line-height: 18px;
          padding: 6px 16px;
          margin: 6px 3px 0px;

          &.active {
            background: $bahama-blue;
            color: $white;
          }
        }
      }
    }
  }
}