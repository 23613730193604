$minLargeDeviceBrakePoint: 1440px;
$maxMediumDeviceBrakePoint: 1439px;
$mobileBreakPoint: 1280px;
$maxTabletBreakPoint: 1199px;
$tabletLandscapeBreakPoint: 1024px;
$tabletBreakPoint: 768px;
$minTabletBreakPoint: 767px;
$minMobileBreakPoint: 480px;

$animationDuration: 0.3s;

$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

$normal-style: normal;

$black: #000000;
$blue: #259cf4;
$grey: rgba(0, 0, 0, 0.05);
$grey-middle: rgba(0, 0, 0, 0.25);
$blur: rgba(255,255,255,.8);
$white: #ffffff;
$dark-grey: #585a6b;
$light-grey: #999;
$lighter-grey: #d6d6d6;
$bahama-blue: #00629B;
$bahama-blue-hover: #005282;
$iron: #E1E3E6;
$rolling-stone: #76787A;
$monza: #DC002E;
$chablis: #FFF6F6;
$stiletto: #9F3A38;
$cod-gray:  #0A0A0A;
$tangaroa:  #031030;
$shark:  #19191A;
$aluminium:  #AAAEB3;
$oliveDrab:  #6A982C;
$tango:  #F38316;
$cornflowerBlue: #9CB7DD;
$banube: #5A88C6;
$pattensBlue:  #D6F0FF;
$alabaster:  #F9F9F9;
$aquaHaze:  #EFF4F6;
$abbey:  #454647;
$osloGray: #909499;
$alabaster: #F8F8F8;
$athensGray: #F2F3F5;
$tangerine: #EA9500;
$mandy: #ee556c;
$athensGray: #F1F4F6;
$cafeRoyale: #62340A;
$shark: #2C2D2E;
$flamingo: #ED6627;
$persianGreen: #009B88;
$affair: #844187;
$crimson: #C8102E;
$heather: #B7C9D3;
$lochmara: #0079D1;
$cerulean: #00B4DD;

