@import './../../../styles/variables.scss';

.container-messageWithControlContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: $mobileBreakPoint) {
    flex-direction: column;
    align-items: flex-start;
  }

  .container-messageWithControl-wrapper {
    margin-left: 10px;

    @media (max-width: $mobileBreakPoint) {
      margin-left: 0px;
      margin-top: 12px;
    }
  }
}