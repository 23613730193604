@import './../../../styles/variables.scss';

.react-js-cron {
  &.react-js-cron-error {
    .react-js-cron-field {
      .ant-select {
        &.react-js-cron-select {
          &:not(.ant-select-customize-input) {
            .ant-select-selector {
              background-color: $white;
              border-color: $monza;
            }
          }
        }
      }
    }
  }

  .react-js-cron-field {
    .ant-select {
      &.react-js-cron-select {
        .ant-select-selector {
          transform: rotate(0deg);
          transition: box-shadow 0.1s;
          line-height: 1em;
          min-height: 32px;

          .ant-select-selection-placeholder {
            opacity: 1;
          }
        }

        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            padding: 0 30px 0 16px;
            background: $white;
            border: 1px solid $aluminium;
            border-radius: 6px;
            transition: box-shadow 0.1s;
          }
        }

        &.ant-select-open {
          .ant-select-arrow {
            transform: rotate(180deg);
          }

          .ant-select-selection-item {
            color: $cod-gray;
          }
        }

        .ant-select-arrow {
          right: 11px;
          margin-top: -6px;
          transform: rotate(0deg);
          opacity: 0.8;
          cursor: pointer;
          transition: transform 0.3s;
          color: $aluminium;
          font-size: 0.85714286em;
          font-family: Icons, sans-serif;
          font-weight: normal;
          font-style: normal;
        }

        .ant-select-arrow:after {
          display: block;
          content: "\f078";
        }

        .ant-select-arrow>* {
          display: none;
        }
      }
    }
  }
}

.react-js-cron-select-dropdown {
  padding: 4px 0;
  border-radius: 2px;

  .ant-select-item-option-selected {
    &:not(.ant-select-item-option-disabled) {
      font-weight: bold;
      background: rgba(0, 0, 0, 0.03);
      color: rgba(0, 0, 0, 0.95);
    }
  }
}

.container-cronWrapper {
  &+.formError {
    padding-top: 0px;
  }
}