@import './../../styles/variables.scss';

.container-exportJobsWrapper {

  .container-exportJobsNotifications {
    width: 100%;
    max-width: 800px;
    margin-right: 24px;
    min-height: 48px;

    @media (max-width: $mobileBreakPoint) {
      margin-right: 0px;
      margin-top: 0px;
      max-width: 100%;
      min-height: auto;
      position: fixed;
      z-index: 5;
      top: 12px;
      left: 68px;
      right: 12px;
      height: 10px;
      width: auto;
    }
  }

  .container-topExportJobsBar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;

    @media (max-width: $mobileBreakPoint) {
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    .container-createExportJobsControl {
      .createExportJobsButton {
        margin-right: 0px;
      }
    }
  }
}