@import '../../../styles/variables.scss';


.homeGetReportsConfig-message {
  &.ui {
    &.message {
      &.error {
        margin-bottom: 24px;
      }
    }
  }
}

.container-reportCards {
  &.ui {
    &.cards {
      max-width: 1400px;

      &>.ui {
        &.card {
          @media (max-width: $maxTabletBreakPoint) {
            width: 200px;
          }
        }
      }
    }
  }
}