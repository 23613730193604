@import './../../../../styles/variables.scss';

.DownloadReport {
  width: 330px;
  position: relative;

  .DownloadScheduledReport_SubTitle {
    display: block;
    margin-top: -12px;
    margin-bottom: 24px;
  }

  .container-additionalControls {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ui.button.resendMFATextControl {
      font-weight: $bold;
      color: $bahama-blue;
      transition: 0.3s;
      height: auto;
      font-size: 16px;
      line-height: 1;
      padding: 0px;

      &.disabled {
        color: $grey-middle;
      }

      &:hover,
      &.disable:hover {
        background-color: transparent;
        color: $bahama-blue-hover;
      }
    }
  }

  .container-downloadScheduledReportMessage {
    margin-top: 12px;
    position: absolute;
    width: 100%;
  }
}