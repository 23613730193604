@import './../../../styles/variables.scss';

.container-exportJobShortDetails {
  &>.item:not(:first-child) {
    @media (max-width: $mobileBreakPoint) {
      display: none;
    }
  }

  .container-jobHistoryTitle,
  h2.container-jobHistoryTitle:first-child {
    margin: 0px;
    display: flex;
    align-items: center;
    word-break: break-word;

    .label {
      margin-left: 10px;
      display: block;
    }
  }

  .container-jobHistoryTitleLabel {
    .label {
      position: relative;
      top: 1px;
    }
  }
}

.container-viewExportJob {
  padding: 24px;
  background: $white;
  box-shadow: 0 2px 1px $grey, 0px 0px 1px $grey-middle;
  border-radius: 8px;
  text-align: left;

  .exportJobName {
    display: flex;
    align-items: center;

    *+.container-enabled {
      margin-left: 10px;
    }
  }

  .container-viewExportJobMainContent {
    padding: 50px 0px;
  }

  .container-viewExportJobSecondaryContent {
    padding-top: 10px;
  }

  .container-deliveryMethodContentWrapper {
    display: flex;
    align-items: flex-start;

    .container-deliveryMethod {
      width: 50%;
    }

    .conteiner-deliveryMethodValue {
      width: 50%;
    }
  }

  .viewExportJobsProvidersList {
    display: flex;
    align-items: center;

    span {
      color: $lighter-grey;

      &+* {
        margin-left: 5px;
      }

      b {
        color: $black;
      }
    }
  }
}