@import '../../styles/variables.scss';

.profile {
  margin-top: 16px;

  &.auditInfo {
    width: auto;
    margin: 0px;
    min-height: 400px;
  }

  .ui.form .fields > .field {
    min-width: 130px;

    & + .field {
      margin-left: 32px;
    }
  }
}
