@import './../../../../../styles/variables.scss';

.MFAScheduledReportForm {
  width: 330px;

  &.ui {
    &.form {
      width: 330px;

      .container-logo {
        margin-bottom: 60px;
      }

      .MFASubTitle {
        display: block;

        @media (max-height: 720px) {
          margin-bottom: 24px;
        }
      }

      .container-MFACode {
        margin-top: 32px;

        input {
          text-align: center;
        }

        &+* {
          margin-top: 24px;
        }
      }

      .container-additionalControls {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .MFATextControl,
        .ui.button.MFATextControl {
          font-weight: $bold;
          color: $bahama-blue;
          transition: 0.3s;
          height: auto;
          font-size: 16px;
          line-height: 1;
          padding: 0px;

          &.disabled {
            color: $grey-middle;
          }

          &:hover,
          &.disable:hover {
            background-color: transparent;
            color: $bahama-blue-hover;
          }
        }
      }
    }
  }

  .container-mfaMessage {
    position: absolute;
    width: 100%;
    margin-top: 12px;
  }
}