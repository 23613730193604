/*******************************
         Site Overrides
*******************************/

.ui.form .ui.small.input input {
  height: 32px;
  padding: 7px 16px;
  line-height: @fieldsLineHeight;
}

.ui.form .ui.input.error > input {
  background-color: @errorBackground;
  border-color: @errorBorder;
  color: @errorColor;
}
