/*******************************
         Site Overrides
*******************************/

.ui.divider{
  margin: 16px 0px
}
.ui.vertical.divider:before,
.ui.vertical.divider:after{
  height: 100%;
}
