@import './../../../styles/variables.scss';

.container-GetReportForm {
  min-height: 100vh;
  display: flex;

  .container-GetReportContent {
    padding: 24px 85px;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    @media (max-height: 720px) {
      padding: 16px 88px;
    }

    @media (max-width: 1024px) {
      width: 100%;
      align-items: center;
    }

    @media (max-width: 560px) {
      padding: 24px;
    }
  }

  .banner-GetReport {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url("../../../assets/banner.png");
    background-size: cover;
  }
}