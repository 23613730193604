@import '../../../styles/variables.scss';

.reportCard {
  &.ui {
    &.card {
      .meta {
        &.reportCard-category {
          margin-bottom: 10px;
          font-size: 1rem;
        }
      }

      .description {
        &.reportCard-description {
          min-height: 80px;
        }
      }

      @media (max-width: $maxTabletBreakPoint) {
        .icon {
          font-size: 2rem;
        }
      }
    }
  }
}

.loadingReportCard {
  .ui {
    &.loader {
      display: block;
    }
  }

  .icon {
    opacity: 0.5;
  }

  .loadingReportCard-loader {
    height: 160px;
    position: relative;
  }
}

.failReportCard {
  .failReportCard-content {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}