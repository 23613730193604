@import './../../../styles/variables';

.administration-tab.featureAccessGroups {
  .buttons-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;


    .ui.message {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1001;
      max-width: 600px;
      text-align: left;
    }

    .button {
      margin-left: auto;
    }
  }

  .administration-tab-container {
    display: flex;
    align-items: flex-start;

    .administration-tabContent-container {
      width: 100%;
    }
  }
}